import React from "react";
import {Link} from "react-router-dom"

import {FiArrowDownCircle} from "react-icons/fi"
import Message from "../lang/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { fontSize } from "../config";

export default class Intro extends React.Component{

    render()
    {
        return(
            <Link to={"/q1"} style={{textDecoration:"none"}}>
                <div style={{
                    height:"100vh",
                    width:"inherit",
                    //border:"1px solid red",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding:"2%"
                }}
                >

                <img style={{marginBottom:"100px",height:"15vh"}} src="/assets/images/ZOOGY.png" />

                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(22),
                    fontWeight:"bold",
                    marginBottom:"5vh"
                }}>
                    <Message text="introText1"/>
                </span>


                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(20),
                    width:"70vw",
                    textAlign:"center",
                    marginBottom:"5vh"
                }}>
                <Message text="introText2"/>
                </span>


                <span style={{
                    color:"#DF6F0C",
                    fontFamily:"Lora",
                    fontSize:fontSize(20),
                    fontWeight:"bold",
                    marginBottom:"5vh"
                }}>
                <Message text="introText3"/>
                </span>


                    <div style={{}}>
                        <div
                        style={{
                            backgroundColor:"rgb(3,9,36)",
                            color:"#FFF",
                            fontSize:fontSize(12),
                            fontFamily:"Lora",
                            borderRadius:"20px",
                            border:0,
                            padding:"1vh 2vw",
                            marginBottom:"2vh",
                            cursor:"pointer"
                        }}> <FontAwesomeIcon icon={faCircleChevronDown} style={{}}/> <Message text="suivant" /></div>         
                    </div>

                </div>
            </Link>
        )
    }
}










