import React from "react";
import {Link} from "react-router-dom"

import { connect } from "react-redux";
import { setLang } from "../redux/lang";

//LANGS
import FRLang from "../lang/fr.json"
import ENLang from "../lang/en.json"
import ARLang from "../lang/ar.json"
import { fontSize } from "../config";

const langArray=[FRLang,ENLang,ARLang]

class Main extends React.Component{

    state={
        lang:langArray[0],
        index:0
    }

    nextLang()
    {
        var nextIndex=this.state.index+1
        if(nextIndex>langArray.length-1){
            nextIndex=0
        }
        this.setState({lang:langArray[nextIndex],index:nextIndex})
    }

    textShow(show=true)
    {
        var items=document.getElementsByClassName("mainText")
        for(var item of items){
            item.style.opacity=show?1:0
        }
    }

    animateText()
    {
        this.textShow(true)
        setTimeout(()=>this.textShow(false),1000 * 4)
        setTimeout(()=>this.nextLang(),1000 * 4.5)
    }

    componentDidMount()
    {
        this.animateText()
        setInterval(()=>this.animateText(),1000 * 4.5)
    }
    

    render()
    {
        return(
            <Link className="animate-flicker" to={"/intro"} style={{textDecoration:"none"}}>

                <div style={{
                    height:"100vh",
                    width:"inherit",
                    //border:"1px solid red",
                    paddingTop:30,
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"flex-start",
                    alignItems:"center",
                }}
                >

                <img className="rotating" style={{marginBottom:"100px",height:"20vh"}} src="/assets/images/ZOOGY.png" />

                <span className="mainText" style={{
                    color:"#DF6F0C",
                    textTransform:"uppercase",
                    fontFamily:"Lora",
                    fontSize:fontSize(25),
                    letterSpacing:"2px",
                    textShadow:"1px 2px 3px rgba(0,0,0,0.3)",
                    opacity:0,
                    height:"18vh"
                }}>
                {this.state.lang["main1"]}
                </span>

                <div style={{marginTop:"10vh",textAlign:"center"}}>
                    <span className="mainText" style={{
                            color:"#DF6F0C",
                            textTransform:"uppercase",
                            fontSize:fontSize(10),
                            letterSpacing:"2px",
                            textShadow:"1px 2px 3px rgba(0,0,0,0.3)",
                            opacity:0
                        }}>
                        {this.state.lang["main2"]}
                    </span>

                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",gap:100,marginTop:"5vh"}}>

                        <div onClick={()=>this.props.dispatch(setLang("FR"))}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"transparent",height:"15vh",width:180,overflow:"hidden",padding:0,borderRadius:10}}>
                                <img src="/assets/lang/fr_drap.gif" style={{height:"15vh"}} />
                            </div>

                            <div style={{marginTop:30,display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <div style={{backgroundColor:"rgba(0,0,0,0.8)",color:"#FFF",display:"flex",justifyContent:"center",alignItems:"center",fontSize:fontSize(10),borderRadius:10,width:"5vw"}}>FR</div>
                            </div>
                        </div>
                        

                        <div onClick={()=>this.props.dispatch(setLang("EN"))}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"transparent",height:"15vh",width:180,overflow:"hidden",padding:0,borderRadius:10}}>
                                <img src="/assets/lang/en_drap.gif" style={{height:"15vh"}} />
                            </div>

                            <div style={{marginTop:30,display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <div style={{backgroundColor:"rgba(0,0,0,0.8)",color:"#FFF",display:"flex",justifyContent:"center",alignItems:"center",fontSize:fontSize(10),borderRadius:10,width:"5vw"}}>EN</div>
                            </div>
                        </div>


                        <div onClick={()=>this.props.dispatch(setLang("AR"))}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:"transparent",height:"15vh",width:180,overflow:"hidden",padding:0,borderRadius:10}}>
                                <img src="/assets/lang/ar_drap.gif" style={{height:"15vh"}} />
                            </div>

                            <div style={{marginTop:30,display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <div style={{backgroundColor:"rgba(0,0,0,0.8)",color:"#FFF",display:"flex",justifyContent:"center",alignItems:"center",fontSize:fontSize(10),borderRadius:10,width:"5vw"}}>AR</div>
                            </div>
                        </div>
                        
                    </div>
                    

                </div>

                </div>
            </Link>
        )
    }
}



const mapStateToProps=state=>{
    return{
        lang:state.lang
    }
  }
  export default connect(mapStateToProps)(Main)






