import {BASE_URL} from "../config"

export default async function request()
{   
    console.log("PING")
    
    const borne=localStorage.getItem("borne")

    try
    {
        fetch(BASE_URL+"/borne/ping?borne="+borne)

    }catch(e)
    {
        console.error("PING",e)
    }
}