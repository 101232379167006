import React from "react";
import {Link} from "react-router-dom"

import {setData,getData, fontSize} from "../config"

import {FiArrowDownCircle} from "react-icons/fi"

import api_sync from "../api/sync"
import Message from "../lang/Message";

export default class Finish extends React.Component{

    componentDidMount()
    {
        setTimeout(()=>{
            this.props.router.redirectTo("/")
        },10*1000)

        api_sync()
    }

    render()
    {
        return(
            <div style={{
                height:"100vh",
                width:"inherit",
                //border:"1px solid red",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                padding:"2%"
            }}
            >

            <img style={{marginBottom:"15vh",height:"15vh"}} src="/assets/images/ZOOGY.png" />

                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(20),
                    textAlign:"center",
                    marginBottom:"5vh"
                }}>
                <Message text="finish1" />
                </span>

                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(20),
                    marginBottom:"5vh",
                    width:"75vw",
                    textAlign:"center"
                }}>
                <Message text="finish2" />
                </span>
            </div>
        )
    }
}










