import {BASE_URL,getLeads} from "../config"

export default async function request()
{   
    console.log("SYNC")
    try
    {
        const borne=localStorage.getItem("borne")
        const leads=await getLeads()
        var ids=leads.map(l=>l.id)

        var formData=new FormData()
        formData.append("ids[]",ids)

        var req=await fetch(BASE_URL+"/borne/sync?borne="+borne,{
            method:"post",
            body:formData
        })

        var res=await req.json()
        
        if(res.ids.length>0)
        {
            console.log("SYNC",res.ids.length)
            
            var finalLeads=[]

            for(var l of leads)
            {
                if(res.ids.includes(l.id)){
                    finalLeads.push(l)
                }
            }

            if(finalLeads.length>0)
            {
                var formData2=new FormData()
                formData2.append("leads",JSON.stringify(finalLeads))

                await fetch(BASE_URL+"/borne/addLeads?borne="+borne,{
                    method:"post",
                    body:formData2
                })
            }
        }

    }catch(e)
    {
        console.error("SYNC",e)
    }
}