import React from "react";
import {Link} from "react-router-dom"

import {setData,getData, fontSize} from "../config"

import {FiArrowDownCircle} from "react-icons/fi"
import Message from "../lang/Message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";

export default class Check extends React.Component{

    componentDidMount()
    {
        var data=getData()

        if(!data.q1 && !data.q2 && !data.q3 && !data.q4){
            setTimeout(()=>{
                this.props.router.redirectTo("/")
            },10*1000)
        }
    }


    renderOk()
    {
        return(
            <div style={{
                height:"100vh",
                width:"inherit",
                //border:"1px solid red",
                display:"flex",
                flexDirection:"column",
                alignItems:"center",
                padding:"2%"
            }}
            >

            <img style={{marginBottom:"5vh",height:"15vh"}} src="/assets/images/ZOOGY.png" />

            <span style={{
                color:"#000",
                fontFamily:"Libre Baskerville",
                fontSize:fontSize(25),
                fontWeight:"bold",
                marginBottom:"1vh"
            }}>
            <Message text="checkOK1" />
            </span>

            <span style={{
                color:"#000",
                fontFamily:"Libre Baskerville",
                fontSize:fontSize(20),
                fontWeight:"bold",
                marginBottom:"5vh"
            }}>
            <Message text="checkOK2" />
            </span>

            <span style={{
                color:"#DF6F0C",
                fontFamily:"Libre Baskerville",
                fontSize:fontSize(20),
                width:"80vw",
                textAlign:"center",
                marginBottom:"10vh"
            }}>
            <Message text="checkOK3" /><br/>
            <Message text="checkOK4" />
            </span>

            <span style={{
                color:"#000",
                fontFamily:"Lora",
                fontSize:fontSize(20),
                fontWeight:"bold",
                marginBottom:"5vh"
            }}>
            <Message text="checkOK5" />
            </span>

            </div>
        )
    }

    render()
    {
        var data=getData()

        if(!data.q1 && !data.q2 && !data.q3 && !data.q4){
            return this.renderOk()
        }

        return(
            <Link style={{textDecoration:"none"}} to="/form" >
                <div style={{
                    height:"100vh",
                    width:"inherit",
                    //border:"1px solid red",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding:"2%"
                }}
                >

                <img style={{marginBottom:"15vh",height:"15vh"}} src="/assets/images/ZOOGY.png" />

                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(20),
                    textAlign:"center",
                    width:"80vw",
                    marginBottom:"10vh"
                }}>
                <Message text="checkKO1" />
                </span>

                <span style={{
                    color:"#000",
                    fontFamily:"Libre Baskerville",
                    fontSize:fontSize(20),
                    textAlign:"center",
                    width:"70vw",
                    marginBottom:"10vh"
                }}>
                <Message text="checkKO2" />
                </span>

                    <div style={{}}>
                        <div
                        style={{
                            backgroundColor:"rgb(3,9,36)",
                            color:"#FFF",
                            fontSize:fontSize(12),
                            fontFamily:"Lora",
                            borderRadius:"20px",
                            border:0,
                            padding:"1vh 2vw",
                            marginBottom:"2vh",
                            cursor:"pointer"
                        }}> <FontAwesomeIcon icon={faCircleChevronDown} style={{}}/> <Message text="suivant" /></div>         
                    </div>
            </div>
            </Link>
        )
    }
}










