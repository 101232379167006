import React from "react";
import {Link} from "react-router-dom"
import { addLead, fontSize, hideKeyboard, setKeyboardValue, showKeyboard } from "../config";
import Message from "../lang/Message";


export default class Form extends React.Component{

    state={
        nom:"",
        prenom:"",
        tel:"",
        email:"",
        secu:"",
        mutuelle:"",
        adresse:"",
        postal:"",
        ville:"",
    }

    submit()
    {
        var data=JSON.parse(JSON.stringify(this.state))

        addLead(data)

        this.props.router.redirectTo("/finish")
    }

    onFocus=(defaultValue,onChange,onUnFocus=this.onUnFocusPadding)=>
    {
        console.log("ONFOCUS")

        setKeyboardValue(defaultValue)
        showKeyboard((v)=>{
            onChange(v)
            setKeyboardValue(v)
        },onUnFocus)

        document.getElementById("form").style.bottom="20vh"
    }

    onUnFocusPadding(){
        document.getElementById("form").style.bottom=0
    }

    render()
    {
        return(
                <div style={{
                    height:"100vh",
                    width:"inherit",
                    //border:"1px solid red",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding:"1%"
                }}
                >

                <img style={{marginBottom:"3vh",height:"15vh"}} src="/assets/images/ZOOGY.png" />

                <div style={{height:"80vh",width:"80vw",backgroundColor:"#D7E0E6",position:"relative"}} id="form">
                        <form onSubmit={(e)=>{
                            e.preventDefault()
                            this.submit()
                        }}
                        style={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:"center",
                            alignItems:"center",
                        }}
                        >
                        <div style={{
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                            flexDirection:"row",
                            width:"100%",
                            //border:"1px solid red"
                        }}>
                            <div style={{
                                width:"50%",
                                padding:"5%",
                                //border:"1px solid green"
                            }}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form1"/> <span style={{color:"red"}}>*</span> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.nom,(value)=>this.setState({nom:value}))}
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:"0 20px"}} 
                                    type="text"
                                    required onChange={(e)=>this.setState({nom:e.target.value})} value={this.state.nom}/>
                                </div>

                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form2"/> <span style={{color:"red"}}>*</span> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.prenom,(value)=>this.setState({prenom:value}))}
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="text" onChange={(e)=>this.setState({prenom:e.target.value})} value={this.state.prenom} 
                                    required/>
                                </div>

                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form3"/> <span style={{color:"red"}}>*</span> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.tel,(value)=>this.setState({tel:value}))} 
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="tel" onChange={(e)=>this.setState({tel:e.target.value})}  value={this.state.tel} 
                                    required/>
                                </div>

                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form4"/> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.email,(value)=>this.setState({email:value}),this.onUnFocusPadding)} 
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="email" onChange={(e)=>this.setState({email:e.target.value})} value={this.state.email} 
                                    />
                                </div>

                            </div>



                            <div style={{
                                width:"50%",
                                padding:"5%",
                                //border:"1px solid green"
                            }}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form5"/> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.secu,(value)=>this.setState({secu:value}))}  
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="text" onChange={(e)=>this.setState({secu:e.target.value})} value={this.state.secu} 
                                    />
                                </div>

                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form6"/> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.mutuelle,(value)=>this.setState({mutuelle:value}))}  
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="text" onChange={(e)=>this.setState({mutuelle:e.target.value})} value={this.state.mutuelle} 
                                    />
                                </div>

                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBottom:"2vh"}}>
                                    <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form7"/> :</span>
                                    <input
                                    onFocus={()=>this.onFocus(this.state.adresse,(value)=>this.setState({adresse:value}))}   
                                    style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                    type="text" onChange={(e)=>this.setState({adresse:e.target.value})} value={this.state.adresse} 
                                    />
                                </div>

                                <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",width:"100%"}}>

                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"40%"}}>
                                        <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form8"/> :</span>
                                        <input
                                        onFocus={()=>this.onFocus(this.state.postal,(value)=>this.setState({postal:value}),this.onUnFocusPadding)}   
                                        style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                        type="text" onChange={(e)=>this.setState({postal:e.target.value})} value={this.state.postal} 
                                        />
                                    </div>

                                    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"50%"}}>
                                        <span style={{fontSize:fontSize(13),fontFamily:"Times"}}><Message text="form9"/> :</span>
                                        <input
                                        onFocus={()=>this.onFocus(this.state.ville,(value)=>this.setState({ville:value}),this.onUnFocusPadding)}    
                                        style={{outline:"none",border:"5px solid #DF6F0C",fontSize:fontSize(10),width:"100%",padding:" 0 20px"}} 
                                        type="text" onChange={(e)=>this.setState({ville:e.target.value})} value={this.state.ville} 
                                        />
                                    </div>
                                
                                </div>

                                

                            </div>
                            </div>

                            <button type="submit" 
                            style={{
                                backgroundColor:"#008037",
                                color:"#FFF",
                                fontSize:fontSize(18),
                                fontFamily:"Lora",
                                borderRadius:"20px",
                                border:0,
                                padding:"1vh 5vw",
                                marginBottom:"2vh"
                            }}>Valider</button>

                        </form>
                    
                </div>

                </div>
        )
    }
}










