import React from "react";
import {connect} from "react-redux"

//LANGS
import FRLang from "./fr.json"
import ENLang from "./en.json"
import ARLang from "./ar.json"

class Message extends React.Component{

    render()
    {
        const {text,lang}=this.props

        var finalText=null

        switch(lang)
        {
            case "FR":
                finalText=FRLang[text]
                break
            case "EN":
                finalText=ENLang[text]
                break
            case "AR":
                finalText=ARLang[text]
                break;
        }
        
        if(!finalText){
            finalText=FRLang[text]
        }
        
        return finalText || ""
    }
}

const mapStateToProps=state=>{
    return{
        lang:state.lang
    }
}
export default connect(mapStateToProps)(Message)