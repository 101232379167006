import React from "react";
import { Route, Navigate,useLocation } from 'react-router-dom'
import SlideRoutes from 'react-slide-routes';



//SCREENS
import Main from "./screens/main";
import Intro from "./screens/intro";
import Q1 from "./screens/q1";
import Q2 from "./screens/q2";
import Q3 from "./screens/q3";
import Q4 from "./screens/q4";
import Check from "./screens/check";
import Form from "./screens/form";
import Finish from "./screens/finish";


 class Routing extends React.Component{

    constructor(props)
    {
        super(props)
        props.getRef(this)
    }

    state={
        redirect:null
    }

    redirectTo(route)
    {
        this.setState({redirect:route})
    }

    hideArrowBack()
    {
        this.props.parent.forceHideArrowBack()
    }

    render()
    {
        if(this.state.redirect!==null)
        {
            this.setState({redirect:null})
            return <Navigate to={this.state.redirect} />
        }
        
        return(
            <SlideRoutes location={this.props.location} duration={300} animation="vertical-slide" >
                <Route exact path='/' element={<Main router={this}/>}/>
                <Route exact path='/intro' element={<Intro router={this}/>}/>
                <Route exact path='/q1' element={<Q1 router={this}/>}/>
                <Route exact path='/q2' element={<Q2 router={this}/>}/>
                <Route exact path='/q3' element={<Q3 router={this}/>}/>
                <Route exact path='/q4' element={<Q4 router={this}/>}/>
                <Route exact path='/check' element={<Check router={this}/>}/>
                <Route exact path='/form' element={<Form router={this}/>}/>
                <Route exact path='/finish' element={<Finish router={this}/>}/>
            </SlideRoutes>
        )
    }
}

export default withLocation(Routing)

function withLocation(Component) {
    return function WrappedComponent(props) {
      const location = useLocation();
      return <Component {...props} location={location} />;
    }
}