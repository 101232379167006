import { createSlice } from '@reduxjs/toolkit'

export const langSlice = createSlice({
  name: 'lang',
  initialState: "FR",
  reducers: {
    setLang: (state,action) => {
      return action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setLang } = langSlice.actions

export default langSlice.reducer