import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import {ClearCacheProvider} from 'react-clear-cache';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import store from './redux/store'
import { Provider } from 'react-redux'

ReactDOM.render(
  <ClearCacheProvider auto basePath='/'>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
  </ClearCacheProvider>
  ,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
