import React from "react";
import Routing from "./Routing"
import IdleTimer from 'react-idle-timer'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretLeft,faCircle,faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import {setData,getLeads,addLead} from "./config"

import api_ping from "./api/ping"
import api_sync from "./api/sync"
import { connect } from "react-redux";
import { setLang } from "./redux/lang";
import Message from "./lang/Message";
import CustomKeyboard from "./components/CustomKeyboard";


class App extends React.Component{

  constructor(props)
  {
    super(props)

    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("borne"))
    {
      localStorage.setItem("borne",urlParams.get("borne"))
    }
  }
  
  state={
    displayArrowBack:false,
    displayCancel:false,
  }

  forceZoom()
  {
    function gcd (a, b) {
      return (b == 0) ? a : gcd (b, a%b);
    }
    var w = window.screen.width;
    var h = window.screen.height;
    var r = gcd (w, h);

    var zoom=1
    if(w/r != 16 && h/r != 9)
    {
      zoom=0.67
    }

    const metaContent='user-scalable=no, initial-scale='+zoom+', maximum-scale='+zoom+', minimum-scale='+zoom+', width=device-width, height=device-height, target-densitydpi=device-dpi'

    var oldmeta=document.querySelector("\\meta[name=viewport]")
    oldmeta.parentNode.removeChild(oldmeta)

    var head=document.getElementsByTagName("head")[0]

    var meta=document.createElement("meta")
    meta.setAttribute("name","viewport")
    meta.setAttribute("content",metaContent)
    head.appendChild(meta)

  }

  componentDidMount()
  {
    //this.forceZoom()

    //PING
    api_ping()
    setInterval(()=>api_ping(),1*60*1000)

    //SYNC
    api_sync()
    setInterval(()=>api_sync(),5*60*1000)
    
    //ARROW BACK
    setInterval(()=>{
      if(window.location.pathname!=this.prevLocation)
      {

        if(this.bgvideo){
          if(window.location.pathname=="/"){
            this.bgvideo.play()
          }else{
            this.bgvideo.pause()
          }
        }

        if(window.location.pathname=="/" && this.props.lang!="FR"){
          this.props.dispatch(setLang("FR"))
        }
        

        if(window.location.pathname=="/" && this.state.displayArrowBack){
          this.setState({displayArrowBack:false})
          setData({})
        }

        if(window.location.pathname!="/" && window.location.pathname!="/form" && !this.state.displayArrowBack){
          this.setState({displayArrowBack:true})
        }

        if((window.location.pathname=="/form" || window.location.pathname=="/finish") && this.state.displayArrowBack){
          this.setState({displayArrowBack:false})
        }

        if(window.location.pathname=="/form" && !this.state.displayCancel){
          this.setState({displayCancel:true})
        }
        if(window.location.pathname!="/form" && this.state.displayCancel){
          this.setState({displayCancel:false})
        }
      }
      
    },100)
  }

  forceHideArrowBack()
  {
    this.setState({displayArrowBack:false})
  }


  render()
  {
    return (
      <div >
          <div id="app">
  
              <video ref={(ref)=>this.bgvideo=ref} id="background-video" onPlay={(e)=>e.target.playbackRate=1} autoPlay muted loop src="/assets/images/bg.mov" playsInline></video>

              <div id="main">

                  {this.state.displayArrowBack && 
                    <div style={{position:"absolute",top:"3vh",left:"2vw"}}>
                      <div onClick={()=>window.history.back()}
                        style={{
                            backgroundColor:"rgb(3,9,36)",
                            color:"#FFF",
                            fontSize:"35px",
                            fontFamily:"Lora",
                            borderRadius:"20px",
                            border:0,
                            padding:"1vh 2vw",
                            marginBottom:"2vh",
                            cursor:"pointer"
                        }}> <FontAwesomeIcon icon={faCircleChevronLeft} style={{}}/> <Message text="retour" /></div>         
                  </div>
                  }

                  {this.state.displayCancel && 
                    <div style={{position:"absolute",top:"3vh",left:"2vw"}}>
                      <button onClick={()=>this.router.redirectTo("/")}
                        style={{
                            backgroundColor:"#dc3545",
                            color:"#FFF",
                            fontSize:"35px",
                            fontFamily:"Lora",
                            borderRadius:"20px",
                            border:0,
                            padding:"1vh 2vw",
                            marginBottom:"2vh"
                        }}>Annuler</button>         
                  </div>
                  }

                  <Routing getRef={(ref)=>this.router=ref} parent={this} />
               
              </div>
              

              
  
          </div>

          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000*60*5}
            onActive={()=>{}}
            onIdle={()=>this.router.redirectTo("/")}
            onAction={()=>{}}
            debounce={250}
          />

          <CustomKeyboard/>

        </div>
    );
  }
  
}


const mapStateToProps=state=>{
  //console.log("STATE",state)
  return{
      lang:state.lang
  }
}
export default connect(mapStateToProps)(App)