import React from "react";
import {Link} from "react-router-dom"

import {setData,getData, fontSize} from "../config"
import Message from "../lang/Message";

export default class Q2 extends React.Component{

    confirm(value)
    {
        var data=getData()
        data.q2=value
        setData(data)
    }

    render()
    {
        return(
                <div style={{
                    height:"100vh",
                    width:"inherit",
                    //border:"1px solid red",
                    display:"flex",
                    flexDirection:"column",
                    alignItems:"center",
                    padding:"2%"
                }}
                >

                <img style={{marginBottom:"100px",height:"15vh"}} src="/assets/images/ZOOGY.png" />


                <span style={{
                    color:"#000",
                    fontFamily:"Arimo",
                    fontSize:fontSize(30),
                    textAlign:"center",
                    width:"80vw",
                    fontWeight:"bold",
                    marginBottom:"5vh"
                }}>
                
                <Message text="q2Text1" />
                </span>

                <span style={{
                    color:"#000",
                    fontFamily:"Open Sans",
                    fontSize:fontSize(20),
                    textAlign:"center",
                    width:"80vw",
                    marginBottom:"15vh"
                }}>
                <Message text="q2Text2" />
                </span>


                <div style={{
                    width:"80vw",
                    display:"flex",
                    flexDirection:"row",
                    alignItems:"center",
                    justifyContent:'space-around'
                }}>

                <Link style={{textDecoration:"none"}} to={"/q3"} onClick={()=>this.confirm(false)}>
                    <span style={{
                        backgroundColor:"#DF6F0C",
                        color:"#FFF",
                        borderRadius:"50px",
                        fontSize:fontSize(25),
                        padding:"2vh 3vw",
                        fontFamily:"Lora",
                        boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}><Message text="buttonKO" /></span>
                </Link>


                <Link style={{textDecoration:"none"}} to={"/q3"} onClick={()=>this.confirm(true)}>
                    <span style={{
                        backgroundColor:"#DF6F0C",
                        color:"#FFF",
                        borderRadius:"50px",
                        fontSize:fontSize(25),
                        padding:"2vh 3vw",
                        fontFamily:"Lora",
                        boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                    }}><Message text="buttonOK" /></span>
                </Link>
                
                </div>

            </div>
        )
    }
}










