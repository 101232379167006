import React from "react";
import { getKeyboardChange, hideKeyboard, onFocusKeyboard, setKeyboardRef } from "../config";

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css'

import layoutFR from "simple-keyboard-layouts/build/layouts/french";


export default class CustomKeyboard extends React.Component{

    constructor(props){
        super(props)
        setKeyboardRef(this)
    }

    state={
        visible:false,
        layout:"default",
        lockMaj:false
    }

    handleShift = () => {
        const newLayoutName = this.state.layout === "default" ? "shift" : "default";
        this.setState({layout:newLayoutName})
    }

    onKeyPress = (button) => {
        console.log("Button pressed", button);

        if(button === "{shift}")
        {
            if(!this.state.lockMaj){
                this.handleShift();
            }
        
        }else if(button === "{lock}")
        {
            this.setState({lockMaj:!this.state.lockMaj})
            this.handleShift();
        
        }else
        {

            if(!this.state.lockMaj){
                this.setState({layout:"default"})
            }
        }
    }

    onUnfocus=(e)=>{  
        //console.log("onUnfocus",e) 
        if (!document.getElementById('keyboard-container').contains(e.target)){
            console.log("CLOSE KEYBOARD")
            hideKeyboard()
        }
    }

    show(){
        window.removeEventListener('click',this.onUnfocus);
        onFocusKeyboard()

        this.setState({visible:true},()=>{
            setTimeout(()=>window.addEventListener('click',this.onUnfocus),100)
        })
    }

    hide(){
        console.log("HIDE KEYBOARD")
        this.keyboard.clearInput();
        this.setState({visible:false},()=>{
            window.removeEventListener('click',this.onUnfocus);
        })
    }

    setValue(v){
        this.keyboard.setInput(v);
    }

    render()
    {
    
        return(
            <div id="keyboard-container" style={{display:this.state.visible?"block":"none",position:"absolute",bottom:0,right:0,left:0,zIndex:100000}}>
                <Keyboard
                    keyboardRef={(ref)=>this.keyboard=ref}
                    onKeyPress={this.onKeyPress}
                    onChange={(v)=>{
                        var onChange=getKeyboardChange()
                        if(onChange){
                            onChange(v)
                        }
                    }}
                    layoutName={this.state.layout}
                    mergeDisplay={true}
                    display={{
                        '{bksp}': 'Retour',
                        '{enter}': 'Entrer',
                        '{tab}': 'Tab',
                        '{shift}': 'Shift',
                        '{lock}': 'Maj.',
                    }}
                    {...layoutFR}
                />
            </div>
            
        )
    }
}