import { openDB } from 'idb';


export const BASE_URL="https://api.zoogy.fr"
//export const FETCH_ERROR_INTERVAL=1000*2

export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}


var data={}

export function setData(data)
{
    data=data
}

export function getData()
{
    return data
}

async function getDb()
{ 
    const db = await openDB('main', 1, {
        upgrade(db) {
            db.createObjectStore('leads',{keyPath:"id"});
        },
      });

      console.log(db)
      return db
}

export async function getLeads()
{
    
    const db = await getDb()

    return await db.getAll("leads")

}

export async function addLead(data={})
{
    data.id=uuidv4()
    data.date=new Date()
    data.events=[{type:"NOUVEAU",date:new Date()}]


    const db = await getDb()

    return await db.add("leads",data)

}

export function fontSize(ratio)
{
    return "calc("+ratio+"*0.1vh + "+ratio+"*0.1vw)"
}


var keyboard=null
var keyboardChange=null
var keyboardOnUnFocus=null

export function setKeyboardRef(ref){
    keyboard=ref
}

export function setKeyboardValue(v){
    if(keyboard){
        keyboard.setValue(v)
    }
}

export function showKeyboard(onChange,onUnFocus=null){
    if(keyboard){
        keyboard.show()
        keyboardChange=onChange
        keyboardOnUnFocus=onUnFocus
    }
}

export function hideKeyboard(){
    if(keyboard){
        keyboard.hide()
        keyboardChange=null

        onFocusKeyboard()
    }
}

export function onFocusKeyboard(){
    if(keyboardOnUnFocus){
        keyboardOnUnFocus()
    }
}

export function getKeyboardChange(){
    return keyboardChange
}